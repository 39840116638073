.dh-article {
  display: inline-block;
  margin-bottom: 50px;
  &:last-child {
    margin-bottom: 0;
  }
  .title-article {
    color: #2b3990;
    font-weight: 700;
  }
  ul {
    li {
      line-height: 24px;
      margin-bottom: 10px;
    }
  }
}

.dh-ul {
  list-style-type: circle;
  margin-left: 100px;
}
