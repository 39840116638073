.tabs-menu {
  font-size: 0;
  clear: both;
  overflow: hidden;
  margin-bottom: 60px;
}

.tabs-menu li {
  width: 50%;
  height: 54px;
  line-height: 54px;
  float: left;
  text-align: center;
  border-bottom: 1px solid #2b3990;
}

.tabs-menu li.current {
  position: relative;
  border-bottom: 3px solid #2b3990;
  z-index: 5;
}

.tabs-menu li a {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: #2b3990;
  text-decoration: none;
}

.tab-content {
  display: none;
}

#tab-1 {
  display: block;
}

.form_bottom {
  margin-top: 80px;
}


/* 
Generic Styling, for Desktops/Laptops 
*/

.table-responsive {
  width: 100%;
  border-collapse: collapse;
}


/* Zebra striping */

th {
  padding: 0 0 25px;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom: 1px solid #959595;
}

td {
  font-size: 16px;
  font-weight: 300;
  padding: 25px 0;
  border-bottom: 1px solid #d7d7d7;
  text-align: left;
}


/* 
Max width before this PARTICULAR table gets nasty
This query will take effect for any screen smaller than 760px
and also iPads specifically.
*/

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  tr {
    border: 1px solid #959595;
  }
  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #d7d7d7;
    position: relative;
    padding-left: 50%;
  }
  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
  /*
    Label the data
    */
  td:nth-of-type(1):before {
    content: "date";
  }
  td:nth-of-type(2):before {
    content: "time";
  }
  td:nth-of-type(3):before {
    content: "Event";
  }
  td:nth-of-type(4):before {
    content: "paid";
  }
}
