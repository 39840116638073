.container_chess {
  text-align: center;
  .title_play_chess {
    font-size: 16px;
    color: #2c2c2c;
    line-height: 22px;
    margin-bottom: 20px;
  }
}

#FenTextBox {
  width: 400px;
}

#TimePerMove {
  width: 50px;
}

.no-highlight {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.sprite-bishop_black {
  background-position: 0 0;
  width: 45px;
  height: 45px;
  margin: 5px auto;
}

.sprite-bishop_white {
  background-position: 0 -95px;
  width: 45px;
  height: 45px;
  margin: 5px auto;
}

.sprite-king_black {
  background-position: 0 -190px;
  width: 45px;
  height: 45px;
  margin: 5px auto;
}

.sprite-king_white {
  background-position: 0 -285px;
  width: 45px;
  height: 45px;
  margin: 5px auto;
}

.sprite-knight_black {
  background-position: 0 -380px;
  width: 45px;
  height: 45px;
  margin: 5px auto;
}

.sprite-knight_white {
  background-position: 0 -475px;
  width: 45px;
  height: 45px;
  margin: 5px auto;
}

.sprite-pawn_black {
  background-position: 0 -570px;
  width: 45px;
  height: 45px;
  margin: 5px auto;
}

.sprite-pawn_white {
  background-position: 0 -665px;
  width: 45px;
  height: 45px;
  margin: 5px auto;
}

.sprite-queen_black {
  background-position: 0 -760px;
  width: 45px;
  height: 45px;
  margin: 5px auto;
}

.sprite-queen_white {
  background-position: 0 -855px;
  width: 45px;
  height: 45px;
  margin: 5px auto;
}

.sprite-rook_black {
  background-position: 0 -950px;
  width: 45px;
  height: 45px;
  margin: 5px auto;
}

.sprite-rook_white {
  background-position: 0 -1045px;
  width: 45px;
  height: 45px;
  margin: 5px auto;
}

.container_chess {
  padding: 80px 0;
}

#board {
  text-align: center;
  table {
    margin: 15px auto !important;
  }
}

.widgets {
  position: absolute;
  top: 0px;
  right: 0px;
}
.widgets_app_1 {
  margin-bottom: 10px;
}
.link_play_with {
  height: 45px;
  display: inline-block;
  border-radius: 22px;
  border: 1px solid #2b3990;
  font-size: 18px;
  font-weight: 600;
  color: #2b3990;
  line-height: 43px;
  padding: 0 15px;
  margin-bottom: 20px;
  transition: all 0.4s;
}

.link_play_with:hover {
  background-color: #2b3990;
  color: #fff;
  text-decoration: none;
}

.clearfix {
  clear: both;
}

@include mq_max (992px) {
  .wrap_board {
    padding-bottom: 70px;
  }
  .head_chess {
    text-align: left;
  }
  #board table {
    margin: 15px 0px !important;
  }
  .foot_chess {
    text-align: left;
  }
}

@include mq_max (768px) {
  .widgets {
    position: relative;
  }
  .head_chess,
  .foot_chess {
    text-align: center;
  }
  #board table {
    margin: 15px auto !important;
  }
  .widgets_app {
    width: 50%;
    float: left;
  }
}
@include mq_max (768px) {
  .widgets_app {
    width: 100%;
  }
}
.wrap_board {
  position: relative;
}
