@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}  
}
@mixin mq_min_max($res-min, $res-max) {
    @media screen and (min-width: #{$res-min}+px) and (max-width: #{$res-max}+px)
        {
        @content;
    }
}

@mixin mq_min($res-min) {
    @media screen and (min-width: #{$res-min}) 
        {
        @content;
    }
}

@mixin mq_max($res-max) {
    @media screen and (max-width: #{$res-max}) 
        {
        @content;
    }
}

@mixin font-face($font-family, $file-path, $font-weight, $font-style) {
  @font-face {
    font-family: $font-family;
      src: url('#{$file-path}.eot');
      src: url('#{$file-path}.eot?#iefix') format('embedded-opentype'),
           url('#{$file-path}.woff') format('woff'),
           url('#{$file-path}.ttf') format('truetype'),
           url('#{$file-path}.svg##{$font-family}') format('svg');
    font-weight: $font-weight;
    font-style: $font-style;
  }
}
