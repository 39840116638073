/* events */

.title_event {
  font-size: 26px;
  font-weight: 600;
  color: $color_blue;
  margin-bottom: 60px;
}

.head_events {
  padding: 120px 0 50px;
  font-size: 16px;
  color: #2c2c2c;
  line-height: 24px;
  .item_event {
    margin-bottom: 20px;
  }
  .foot_item_event {
    margin-top: 10px;
    overflow: hidden;
    .link_event_detail {
      width: 169px;
      height: 34px;
      display: inline-block;
      -webkit-border-radius: 17px;
      -moz-border-radius: 17px;
      border-radius: 17px;
      border: 1px solid #2b3990;
      font-size: 14px;
      font-weight: 400;
      color: #2b3990;
      line-height: 32px;
      text-align: center;
      &:hover {
        text-decoration: none;
      }
    }
    .date_event {
      float: right;
      font-size: 16px;
      font-weight: 400;
      color: #2b3990;
      line-height: 34px;
      @include mq_max (480px) {
        float: none;
        display: block;
      }
    }
  }
  b {
    color: #2b3990;
  }
  .img_calender {
    float: right;
    margin-left: 30px;
    margin-bottom: 30px;
    @include mq_max (575px) {
      width: 200px;
    }
    @include mq_max (480px) {
      float: none;
      margin-left: 0px;
      margin-bottom: 10px;
    }
  }
}

.calendar_events {
  padding-bottom: 80px;
}

.monthly {
  box-shadow: 0 13px 40px rgba(0, 0, 0, 0.5);
}

.desc {
  max-width: 250px;
  text-align: left;
  font-size: 14px;
  padding-top: 30px;
  line-height: 1.4em;
}

.resize {
  background: #222;
  display: inline-block;
  padding: 6px 15px;
  border-radius: 22px;
  font-size: 13px;
}

@media (max-height: 700px) {
  .sticky {
    position: relative;
  }
}

@media (max-width: 600px) {
  .resize {
    display: none;
  }
}


/* end events */


/* event_detail */

.event_detail {
  color: #363636;
  .title_page {
    margin-bottom: 23px !important;
  }
  .date_event {
    margin-bottom: 45px;
    font-size: 18px;
  }
  .short_about_event {
    margin-bottom: 58px;
  }
  .text_event_detail {
    font-size: 16px;
    color: #363636;
    line-height: 30px;
    margin-bottom: 2px;
  }
  ul {
    padding-left: 20px;
    margin-bottom: 20px;
  }
  .title_info {
    font-size: 24px;
    font-weight: 600;
    color: #2b3990;
    margin-bottom: 15px;
  }
  .list_info {
    list-style-type: disc;
    line-height: 34px;
    margin-bottom: 34px;
  }
  .list_info_2 {
    list-style: none;
    line-height: 34px;
    li:before {
      content: "‒";
      position: absolute;
      left: 15px;
    }
  }
  .popup_participate {
    width: 388px;
    height: 45px;
    display: block;
    margin: 45px auto 0;
    border: none;
    -webkit-border-radius: 21px;
    -moz-border-radius: 21px;
    border-radius: 21px;
    border: 2px solid #3f55bb;
    background-color: transparent;
    font-size: 18px;
    font-weight: 600;
    color: #3f55bb;
    &:hover {
      border: none;
      background-color: #3f55bb;
      color: #fff;
    }
    @include mq_max (480px) {
      width: 100%;
    }
  }
  .about_event {
    margin-top: 70px;
    .item_about {
      margin-bottom: 17px;
    }
    .label_about {
      width: 290px;
      float: left;
      p {
        font-size: 18px;
        font-weight: 600;
        color: #2b3990;
      }
      @include mq_max (768px) {
        width: 100%;
        float: none;
      }
    }
    .text_about {
      overflow: hidden;
      p {
        line-height: 22px;
      }
    }
  }
}

.modal_participate_event {
  .modal-content {
    box-shadow: none;
    border-radius: 0px;
  }
  .modal-dialog {
    @media (min-width: 768px) {
      width: 100%;
    }
    @media (min-width: 1200px) {
      width: 1170px;
    }
    .modal-header {
      padding-top: 50px;
      text-align: center;
    }
    .modal-header,
    .modal-footer {
      border: none;
    }
    .modal-body {
      padding: 15px 40px;
      @include mq_max (768px) {
        padding: 20px;
      }
    }
    .modal-header {
      padding-left: 40px;
      padding-right: 40px;
      @include mq_max (768px) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    .title_page {
      font-size: 26px;
      font-weight: 600;
      color: #2b3990;
      margin-bottom: 30px;
    }
    .date_event {
      margin-bottom: 45px;
      font-size: 18px;
      color: #363636;
    }
    .short_about_event {
      margin-bottom: 50px;
      font-size: 16px;
      color: #363636;
      line-height: 18px;
      text-align: left;
      p {
        margin-bottom: 10px;
      }
    }
    .title_form {
      padding: 10px 0;
      font-size: 24px;
      font-weight: 600;
      line-height: 28px;
      color: #2b3990;
      border-bottom: 1px solid #2b3990;
    }
    .wrap_form {
      padding: 50px 0;
      .item_form_group {
        clear: both;
        margin-top: -8px;
        .wrap_form_group,
        .wrap_label {
          padding-top: 8px;
        }
      }
      .item_form_group_last {
        margin-top: 50px;
        .total_text {
          font-size: 24px;
          font-weight: 600;
          line-height: 28px;
          color: #363636;
          display: inline-block;
          @include mq_max (768px) {
            display: block;
          }
        }
        .btn {
          float: right;
          width: 170px;
          height: 33px;
          border-radius: 16px;
          font-size: 14px;
          font-weight: 600;
          text-transform: uppercase;
          @include mq_max (768px) {
            margin-top: 15px;
          }
        }
        .btn_cancel {
          margin-left: 20px;
          background-color: transparent;
          border: 1px solid #2b3990;
          color: #2b3990;
          &:hover {
            border: none;
            background-color: #2b3990;
            color: #fff;
          }
        }
        .btn_submit {
          background-color: #2b3990;
          color: #fff;
          &:hover {
            border: 1px solid #2b3990;
            background-color: transparent;
            color: #2b3990;
          }
        }
      }
    }
    .wrap_label {
      width: 150px;
      float: left;
      p {
        font-size: 14px;
        font-weight: 600;
        color: #363636;
        span {
          color: #ff6969;
        }
      }
      span {
        font-size: 12px;
      }
      @include mq_max (768px) {
        width: 120px;
      }
    }
    .wrap_form_group {
      overflow: hidden;
    }
    .wrap_form_group {
      .group {
        position: relative;
        margin-bottom: 45px;
      }
      .group_inline {
        display: inline-block;
        @include mq_max (992px) {
          display: block;
        }
      }
      .group_psevdo {
        width: 250px;
        @include mq_max (1200px) {
          width: 225px;
        }
        @include mq_max (992px) {
          display: none;
        }
      }
      .group_block {
        .form_control,
        .bar {
          width: 100%;
        }
      }
      &.wrap_form_group_flex {
        display: flex;
        justify-content: space-between;
        @include mq_max (992px) {
          display: block;
        }
      }
      .form_control {
        font-size: 14px;
        font-weight: 300;
        color: #363636;
        padding: 7px 10px 7px 5px;
        display: block;
        width: 250px;
        border: none;
        border-bottom: 1px solid #363636;
      }
      .wrap_radio {
        display: inline-block;
        margin-right: 50px;
        width: 20%;
        vertical-align: top;
        label {
          font-size: 14px;
          font-weight: 300;
          color: #363636;
          padding-left: 30px;
          line-height: 15px;
          position: relative;
          cursor: pointer;
          &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            border: 2px solid #4c4c4c;
          }
          @include mq_max (768px) {
            margin-bottom: 15px;
          }
        }
        input {
          display: none;
        }
        input:checked + label {
          &:before {
            border: none;
            background-color: #41a840;
          }
        }
      }
      .form_control:focus {
        outline: none;
      }
      /* LABEL ======================================= */
      .label_input {
        font-size: 14px;
        font-weight: 300;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 15px;
        top: 10px;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
        span {
          color: #ff5e5e;
        }
      }
      /* active state */
      .form_control:focus ~ .label_input,
      .form_control:valid ~ .label_input {
        top: -10px;
        font-size: 14px;
        color: #2b3990;
      }
      /* BOTTOM BARS ================================= */
      .bar {
        position: relative;
        display: block;
        width: 250px;
      }
      .bar:before,
      .bar:after {
        content: '';
        height: 1px;
        width: 0;
        bottom: 1px;
        position: absolute;
        background: #363636;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
      }
      .bar:before {
        left: 50%;
      }
      .bar:after {
        right: 50%;
      }
      /* active state */
      .form_control:focus ~ .bar:before,
      .form_control:focus ~ .bar:after {
        width: 50%;
      }
      /* HIGHLIGHTER ================================== */
      .highlight {
        position: absolute;
        height: 60%;
        width: 100px;
        top: 25%;
        left: 0;
        pointer-events: none;
        opacity: 0.5;
      }
      @include mq_max (1200px) {
        .form_control,
        .bar {
          width: 225px;
        }
      }
      @include mq_max (992px) {
        .form_control,
        .bar {
          width: 100%;
        }
      }
      /* active state */
      .form_control:focus ~ .highlight {
        -webkit-animation: inputHighlighter 0.3s ease;
        -moz-animation: inputHighlighter 0.3s ease;
        animation: inputHighlighter 0.3s ease;
      }
      /* ANIMATIONS ================ */
      @-webkit-keyframes inputHighlighter {
        from {
          background: #5264AE;
        }
        to {
          width: 0;
          background: transparent;
        }
      }
      @-moz-keyframes inputHighlighter {
        from {
          background: #5264AE;
        }
        to {
          width: 0;
          background: transparent;
        }
      }
      @keyframes inputHighlighter {
        from {
          background: #5264AE;
        }
        to {
          width: 0;
          background: transparent;
        }
      }
      /* checkbox */
      $baseFontSize: 16;
      $slideDistance: 100;
      $slideDuration: .4s;
      @function rem($val) {
        @return #{$val / $baseFontSize}rem;
      }
      .list__item {
        margin: 0 0 1rem 0;
        padding: 0;
      }
      .label--checkbox {
        position: relative;
        line-height: 135%;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: #363636;
        span {
          font-weight: 600;
        }
      }
      .checkbox {
        position: relative;
        top: 0.125rem;
        ;
        margin: 0 1rem 0 0;
        cursor: pointer;
        float: left;
        &:before {
          transition: all .3s ease-in-out;
          content: "";
          position: absolute;
          left: 0;
          z-index: 1;
          width: 1rem;
          height: 1rem;
          -webkit-border-radius: 2px;
          -moz-border-radius: 2px;
          border-radius: 2px;
          border: solid 2px #000;
        }
        &:checked {
          &:before {
            transform: rotate(-45deg);
            height: .5rem;
            border-color: #3f51b5;
            border-top-style: none;
            border-right-style: none;
          }
        }
        &:after {
          content: "";
          position: absolute;
          top: rem(-2);
          left: 0;
          width: 1.1rem;
          height: 1.1rem;
          background: #fff;
          cursor: pointer;
        }
      }
      .button--round {
        transition: .3s background ease-in-out;
        width: 2rem;
        height: 2rem;
        background: #5677fc;
        border-radius: 50%;
        box-shadow: 0 rem(2) rem(5) 0 rgba(0, 0, 0, 0.25);
        color: #fff;
        text-decoration: none;
        text-align: center;
        i {
          font-size: 1rem;
          line-height: 220%;
          vertical-align: middle;
        }
        &:hover {
          background: #3b50ce;
        }
      }
      .button--sticky {
        position: fixed;
        right: 2rem;
        top: 16rem;
      }
      %slide-up {
        -webkit-animation-duration: $slideDuration;
        animation-duration: $slideDuration;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-name: slideUp;
        animation-name: slideUp;
        -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      }
      @-webkit-keyframes slideUp {
        0% {
          -webkit-transform: translateY(rem($slideDistance));
          transform: translateY(rem($slideDistance));
        }
        100% {
          -webkit-transform: translateY(0);
          transform: translateY(0);
        }
      }
      @keyframes slideUp {
        0% {
          -webkit-transform: translateY(rem($slideDistance));
          transform: translateY(rem($slideDistance));
        }
        100% {
          -webkit-transform: translateY(0);
          transform: translateY(0);
        }
      }
      /* select */
      select {
        background-color: transparent;
        width: 100%;
        padding: 7px 10px 7px 15px;
        font-size: 14px;
        font-weight: 600;
        color: #363636;
        border: none;
        border-bottom: 1px solid #d7d7d7;
      }
      /* Remove focus */
      select:focus {
        outline: none
      }
      /* Hide label */
      .mdl-selectfield label {
        display: none;
      }
      /* Use custom arrow */
      .mdl-selectfield select {
        appearance: none
      }
      .mdl-selectfield {
        width: 250px;
        position: relative;
        &:after {
          position: absolute;
          top: 0.75em;
          right: 0.5em;
          /* Styling the down arrow */
          width: 0;
          height: 0;
          padding: 0;
          content: '';
          border-left: .25em solid transparent;
          border-right: .25em solid transparent;
          border-top: .375em solid #848484;
          pointer-events: none;
        }
        @include mq_max (1200px) {
          width: 225px;
        }
        @include mq_max (992px) {
          width: 100%;
        }
      }
    }
  }
}

#modal_select {
  .group_radio {
    font-size: 0;
  }
  .wrap_radio {
    width: 32%;
    margin: 0 0 20px;
    @include mq_max (768px) {
      width: 50%;
    }
    @include mq_max (480px) {
      width: 100%;
    }
  }
  .modal-dialog {
    .wrap_form_group .group {
      margin-bottom: 15px;
    }
    .wrap_form .item_form_group_last {
      margin-top: 0px;
    }
  }
}


/* end event_detail */
