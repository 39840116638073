/* header */

header {
  min-height: 86px;
  background-color: $bg_head;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: .35s;
  -o-transition-duration: .35s;
  transition-duration: .35s;
  -webkit-transition-property: height, visibility;
  -o-transition-property: height, visibility;
  transition-property: height, visibility;
  &.open-menu {
    height: auto;
    -webkit-transition-timing-function: ease;
    -o-transition-timing-function: ease;
    transition-timing-function: ease;
    -webkit-transition-duration: .35s;
    -o-transition-duration: .35s;
    transition-duration: .35s;
    -webkit-transition-property: height, visibility;
    -o-transition-property: height, visibility;
    transition-property: height, visibility;
  }
  .navbar {
    display: inline-block;
    float: right;
    margin-bottom: 0px;
  }
  .navbar-collapse {
    padding-right: 0px;
    padding-left: 0px;
  }
  .navbar-nav {
    li:last-child a {
      padding-right: 0px;
    }
    a {
      font-size: 18px;
      font-weight: 600;
      padding: 0 34px;
      line-height: 86px;
      color: $color_nav;
      transition: color 0.25s;
      @include mq_max (992px) {
        padding: 0 7px;
      }
      @include mq_max (767px) {
        line-height: 28px;
      }
      &:hover,
      &:focus {
        color: #fff;
        transition: color 0.25s;
        background: transparent;
      }
    }
    @include mq_max (768px) {
      margin: 0px 0px 20px;
    }
  }
  .navbar-toggle {
    border: 1px solid #fff;
    margin-right: 0px;
    .icon-bar {
      background-color: #fff;
    }
  }
  .navbar-collapse {
    box-shadow: none;
    border-top: none;
  }
  .nav .open>a,
  .nav .open>a:focus,
  .nav .open>a:hover {
    background-color: transparent;
    border-color: transparent;
  }
  .dropdown_login {
    left: initial;
    right: 0px;
    width: 321px;
    margin-top: 1px;
    background-color: #fff;
    border-radius: 0px;
    border: none;
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, .35);
    -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, .35);
    box-shadow: 0 10px 20px rgba(0, 0, 0, .35);
    form {
      overflow: hidden;
      padding: 28px 28px 5px;
      border-bottom: 1px solid #ebebeb;
      label {
        display: block;
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 300;
        color: #353535;
      }
      .form_group {
        margin-bottom: 15px;
      }
      .form_control {
        height: 36px;
        width: 100%;
        border: 1px solid #ebebeb;
        border-left: 2px solid #7381dc;
      }
      .wrap_checkbox {
        display: inline-block;
        label {
          font-size: 14px;
          font-weight: 300;
          color: #363636;
          padding-left: 30px;
          line-height: 15px;
          position: relative;
          cursor: pointer;
          &:before {
            content: '';
            position: absolute;
            left: 0px;
            width: 18px;
            height: 18px;
            border: 1px solid #ebebeb;
          }
        }
        input {
          display: none;
        }
        input:checked + label {
          &:before {
            border: none;
            background: url(../img/checked_checkbox.png) center no-repeat;
            border: 1px solid #ebebeb;
          }
        }
        @include mq_max (480px) {
          margin-bottom: 15px;
        }
      }
      .btn_login {
        width: 93px;
        height: 36px;
        float: right;
        border: none;
        background-color: #7381dc;
        font-size: 12px;
        font-weight: 600;
        color: #fff;
        text-transform: uppercase;
      }
    }
    .wrap_foot_login {
      padding: 0 27px;
    }
    .foot_login {
      padding: 20px 0;
      .title_1 {
        font-size: 14px;
        font-weight: 300;
        color: #353535;
        line-height: 26px;
      }
      .title_2 {
        font-size: 12px;
        font-weight: 300;
        color: #353535;
        line-height: 26px;
      }
      a {
        color: #7381dc;
      }
    }
    .forgot_pass {
      border-bottom: 1px solid #ebebeb;
    }
  }
  .logo {
    position: absolute;
  }
  @include mq_max (360px) {
    .logo {
      width: 100px;
    }
  }
}


/* header */
