/* admin */

.head_admin {
  .navbar-nav {
    margin-top: 25px;
  }
  .navbar-nav li {
    float: none;
    text-align: right;
    margin-bottom: 5px;
  }
  .navbar-nav a {
    padding: 0px;
    line-height: 18px;
  }
  .name_admin {
    font-size: 18px;
    font-weight: 600;
    color: $color_nav;
  }
  .logout {
    font-size: 14px;
    font-weight: 400;
    color: #fff;
  }
}

.login {
  .title_admin {
    margin-bottom: 40px;
  }
  label {
    font-size: 18px;
    font-weight: 600;
    color: #2b3990;
    margin-bottom: 20px;
  }
  .form-group {
    margin-bottom: 20px;
  }
  .form-control {
    height: 45px;
    padding: 0 0 0 20px;
    border-radius: 22px;
    border: 1px solid $color_blue;
    font-size: 18px;
    font-weight: 600;
    color: $color_blue;
  }
  .checkbox {
    input {
      display: none;
    }
    input:checked + label {
      &:after {
        font-family: 'Glyphicons Halflings';
        content: "\e013";
        position: absolute;
        left: 3px;
        top: 3px;
        font-size: 13px;
      }
    }
    label {
      position: relative;
      padding-left: 35px;
      &:before {
        content: '';
        position: absolute;
        left: 0px;
        width: 20px;
        height: 20px;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        border: 1px solid $color_blue;
      }
    }
  }
  .btn_send {
    width: 100%;
    height: 45px;
    display: inline-block;
    border: none;
    border-radius: 22px;
    background-color: #fdc00e;
    font-size: 18px;
    font-weight: 600;
    color: $color_blue;
    &:hover {
      background-color: #e9bd25;
    }
  }
}

.admin_block {
  padding: 120px 0;
  .title_admin {
    font-size: 36px;
    font-weight: 600;
    color: $color_blue;
    margin-bottom: 20px;
  }
  .add_news {
    width: 180px;
    height: 45px;
    float: right;
    margin-top: -15px;
    display: inline-block;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border-radius: 22px;
    background-color: $color_orange;
    font-size: 21px;
    font-weight: 600;
    color: $color_blue;
    line-height: 45px;
    text-align: center;
    text-decoration: none;
    &:hover {
      background-color: #e9bd25;
    }
  }
  .table_posts {
    table {
      width: 100%;
      thead {
        background-color: $color_blue;
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        th {
          height: 50px;
          line-height: 50px;
          &:first-child {
            padding-left: 20px;
          }
        }
      }
      tbody {
        border: 1px solid #ebebeb;
        tr {
          background-color: #fff;
          &:nth-child(2n) {
            background-color: #eeeeee;
          }
        }
      }
      td {
        font-size: 18px;
        color: #2d2d2d;
        padding: 7px 0;
        .status {
          width: 150px;
          height: 35px;
          display: inline-block;
          font-size: 18px;
          color: #fff;
          text-align: center;
          line-height: 35px;
          &.published {
            background-color: #39be8a;
          }
          &.pending {
            background-color: #f6c51a;
          }
          &.decline {
            background-color: #f14848;
          }
        }
        &:first-child {
          padding-left: 20px;
        }
      }
    }
    @include mq_max (767px) {
      /* Force table to not be like tables anymore */
      table,
      thead,
      tbody,
      th,
      td,
      tr {
        display: block;
      }
      /* Hide table headers (but not display: none;, for accessibility) */
      table {
        thead tr {
          position: absolute;
          top: -9999px;
          left: -9999px;
        }
        tbody {
          border: none;
        }
        tr {
          border: 1px solid #ccc;
          border-bottom: none;
          &:nth-child(2n) td {
            border-color: #fff;
          }
        }
        td {
          /* Behave  like a "row" */
          border: none;
          border-bottom: 1px solid #eee;
          position: relative;
          padding-left: 50%;
          &:first-child {
            padding-left: 50%;
            background: #2b3990;
            color: #fff;
          }
        }
        td:before {
          /* Now like a table header */
          position: absolute;
          /* Top/left values mimic padding */
          top: 6px;
          left: 6px;
          width: 45%;
          padding-right: 10px;
          white-space: nowrap;
        }
        /*
  Label the data
  */
        td:nth-of-type(1):before {
          content: "ID";
        }
        td:nth-of-type(2):before {
          content: "Title";
        }
        td:nth-of-type(3):before {
          content: "Status";
        }
        td:nth-of-type(4):before {
          content: "Edit";
        }
        td:nth-of-type(5):before {
          content: "Delete";
        }
      }
    }
    @include mq_max (480px) {
      table {
        td {
          padding-left: 40%;
          font-size: 16px;
          padding-top: 5px;
          padding-bottom: 5px;
          &:first-child {
            padding-left: 40%;
          }
        }
      }
    }
  }
  .wrap_pagination {
    .pagination {
      margin-top: 40px;
      .active a {
        color: #fff;
        background-color: $color_blue;
        border-color: $color_blue;
      }
      a {
        font-size: 18px;
        color: $color_blue;
        padding: 4px 11px;
      }
      li:first-child>a,
      li:last-child>a {
        padding: 4px 6px;
      }
      li:first-child>span,
      li:last-child>span {
        font-size: 16px;
      }
      li:first-child>a,
      li:first-child>span {
        border-radius: 0px;
      }
      li:last-child>a,
      li:last-child>span {
        border-radius: 0px;
      }
    }
    .pagination>li>a:focus,
    .pagination>li>a:hover,
    .pagination>li>span:focus,
    .pagination>li>span:hover {
      z-index: initial;
    }
    .pagination>.active>a,
    .pagination>.active>a:focus,
    .pagination>.active>a:hover,
    .pagination>.active>span,
    .pagination>.active>span:focus,
    .pagination>.active>span:hover {
      z-index: initial;
    }
  }
  @include mq_max (992px) {
    padding: 80px 0;
  }
  @include mq_max (360px) {
    padding: 60px 0;
  }
  @include mq_max (767px) {
    .title_admin {
      font-size: 24px;
      margin-bottom: 20px;
    }
    .add_news {
      width: 150px;
      height: 38px;
      margin-top: -15px;
      font-size: 18px;
      line-height: 38px;
    }
  }
}

.add_post {
  label {
    font-size: 18px;
    font-weight: 600;
    color: $color_blue;
  }
  .date {
    .input-group-addon {
      border-radius: 0px;
    }
    .form-control {
      margin-bottom: 0px;
    }
  }
  .form-control {
    min-height: 50px;
    margin-bottom: 9px;
    border: 1px solid #e5e5e5;
    border-radius: 0px;
    box-shadow: none;
    font-size: 18px;
    color: #2d2d2d;
  }
  textarea.form-control {
    resize: none;
    min-height: 345px;
  }
  .wrap_btn {
    margin-top: 30px;
  }
  .btn_cancel {
    width: 142px;
    height: 45px;
    float: right;
    margin-right: 20px;
    display: inline-block;
    border: none;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border-radius: 22px;
    background-color: #e5e5e5;
    font-size: 21px;
    font-weight: 600;
    color: #fff;
    line-height: 45px;
    text-align: center;
    text-decoration: none;
    &:hover {
      background-color: #d6d6d6;
    }
  }
  .btn_save {
    width: 115px;
    height: 45px;
    float: right;
    display: inline-block;
    border: none;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border-radius: 22px;
    background-color: $color_orange;
    font-size: 21px;
    font-weight: 600;
    color: $color_blue;
    &:hover {
      background-color: #e9bd25;
    }
  }
  @include mq_max (767px) {
    .btn_save,
    .btn_cancel {
      height: 38px;
      font-size: 18px;
      line-height: 38px;
    }
    label {
      margin-bottom: 10px;
    }
    .form-control {
      margin-bottom: 20px;
    }
  }
  .custom-file-upload-hidden {
    display: none;
    visibility: hidden;
    position: absolute;
    left: -9999px;
  }
  .custom-file-upload {
    display: block;
    width: auto;
    font-size: 16px;
    margin-bottom: 20px;
    //border: 1px solid #ccc;
    label {
      display: block;
      margin-bottom: 5px;
    }
  }
  .file-upload-wrapper {
    position: relative;
    margin-bottom: 5px;
    //border: 1px solid #ccc;
  }
  .file-upload-input {
    padding: 11px 17px;
    border: none;
    font-size: 18px;
    color: #2d2d2d;
    &:hover,
    &:focus {
      outline: none;
    }
  }
  .file-upload-button {
    width: 290px;
    height: 50px;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    border: none;
    background-color: #e5e5e5;
    float: left;
    /* IE 9 Fix */
    &:hover {
      background-color: #d6d6d6;
    }
    @include mq_max (360px) {
      width: 100%;
    }
  }
}

.modal_del {
  background-color: rgba(43, 57, 144, .15);
  .modal-dialog {
    width: 360px;
    height: 170px;
  }
  .modal-content {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid $color_blue;
  }
  .modal-body {
    text-align: center;
  }
  .text_cancel {
    font-size: 18px;
    font-weight: 600;
    color: $color_blue;
    margin-bottom: 35px;
  }
  .btn_cancel {
    width: 142px;
    height: 45px;
    border: none;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border-radius: 22px;
    background-color: #e5e5e5;
    font-size: 21px;
    font-weight: 600;
    color: #fff;
    vertical-align: top;
    &:focus {
      outline: none;
    }
    &:hover {
      background-color: #d6d6d6;
    }
  }
  .btn_del {
    width: 142px;
    height: 45px;
    margin-right: 15px;
    display: inline-block;
    border: none;
    -webkit-border-radius: 22px;
    -moz-border-radius: 22px;
    border-radius: 22px;
    background-color: $color_orange;
    font-size: 21px;
    font-weight: 600;
    color: $color_blue;
    line-height: 45px;
    text-align: center;
    text-decoration: none;
    &:hover {
      background-color: #e9bd25;
    }
  }
}
