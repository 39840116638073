/* contact */

.contact {
    padding: 125px 0 70px;
    .title_contact {
        font-size: 36px;
        font-weight: 600;
        color: #2b3990;
        margin-bottom: 41px;
    }
    .form-group {
        margin-bottom: 25px;
        label {
            font-size: 18px;
            font-weight: 600;
            color: #2b3990;
            margin-bottom: 20px;
        }
        .form-control {
            height: 45px;
            padding: 0px;
            padding-left: 20px;
            -webkit-border-radius: 22px;
            -moz-border-radius: 22px;
            border-radius: 22px;
            border: 1px solid #2b3990;
            font-size: 18px;
            font-weight: 600;
            color: #2b3990;
            @include placeholder {
                font-size: 14px;
                color: #dbddec;
                line-height: 22px;
            }
            &:focus {
                @include placeholder {
                    color: transparent;
                }
            }
        }
        .error {
        	font-size: 14px;
        	color: red;
        	margin-bottom: 0px;
        }
        textarea.form-control {
            height: 140px;
            padding-top: 15px;
            resize: none;
        }
        .btn_send {
            width: 100%;
            height: 45px;
            margin-top: 8px;
            display: inline-block;
            border: none;
            -webkit-border-radius: 22px;
            -moz-border-radius: 22px;
            border-radius: 22px;
            background-color: #fdc00e;
            font-size: 24px;
            font-weight: 600;
            color: #2b3990;
            &:hover {
                background-color: #e9bd25;
            }
            &:focus {
            	outline: none;
            }
        }
    }
    .wrap_img_chess {
        padding-top: 48px;
        @include mq_max (1200px) {
            padding-top: 63px;
        }
        @include mq_max (767px) {
            display: none;
        }
    }
    .success_mesage {
        font-size: 18px;
        font-weight: 600;
        color: #2b9051;
    }
    @include mq_max (767px) {
        padding: 80px 0 50px;
    }
}


/* end contact */

/* footer_contact */
.footer_contact {
    height: 86px;
    background-color: $bg_head;
    .copy {
        font-weight: 600;
        color: $color_nav;
        line-height: 86px;
    }
}
/* end footer_contact */