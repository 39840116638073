.sing_up_page {
  .title_page {
    text-align: center;
  }
  .item_form_group {
    clear: both;
    margin-bottom: 20px;
  }
  .wrap_label {
    width: 315px;
    min-height: 1px;
    float: left;
    text-align: right;
    padding-right: 40px;
    label {
      font-size: 16px;
      font-weight: 300;
      color: #353535;
      line-height: 36px;
      span {
        color: #ff6969;
      }
    }
    @include mq_max (992px) {
      width: 170px;
      padding-right: 20px;
    }
    @include mq_max (480px) {
      width: 100%;
      float: none;
      text-align: left;
    }
  }
  .wrap_form_field {
    overflow: hidden;
    .form_control {
      width: 516px;
      height: 36px;
      padding-left: 15px;
      border: 1px solid #e1e1e1;
      font-size: 16px;
      font-weight: 400;
      color: #363636;
      @include mq_max (992px) {
        width: 100%;
      }
    }
  }
  .wrap_radio {
    display: block;
    margin-bottom: 25px;
    label {
      font-size: 14px;
      font-weight: 300;
      color: #363636;
      padding-left: 30px;
      line-height: 15px;
      position: relative;
      cursor: pointer;
      &:before {
        content: '';
        position: absolute;
        left: 0px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 1px solid #4c4c4c;
      }
    }
    input {
      display: none;
    }
    input:checked + label {
      &:before {
        border: none;
        background-color: #2b3990;
      }
    }
    @include mq_max (480px) {
      margin-bottom: 15px;
    }
  }
  .item_form_group_last {
    label {
      line-height: 1;
      @include mq_max (480px) {
        margin-bottom: 10px;
      }
    }
  }
  .btn_ {
    width: 93px;
    height: 36px;
    font-size: 14px;
    font-weight: 600;
  }
  .btn_submit {
    border: none;
    background-color: #2b3990;
    color: #fff;
    &:hover {
      border: 1px solid #2b3990;
      background-color: #fff;
      color: #2b3990;
    }
  }
  .link_back {
    margin-left: 15px;
    display: inline-block;
    border: 1px solid #2b3990;
    line-height: 34px;
    text-align: center;
    color: #2b3990;
    &:hover {
      color: #fff;
      background-color: #2b3990;
    }
  }
}
