/* main */

.main {
    padding-top: 99px;
    padding-bottom: 112px;
    .title_site {
        font-size: 36px;
        font-weight: 600;
        color: $color_orange;
        text-transform: uppercase;
        margin: 66px 0 23px;
        span {
            font-weight: 700;
            color: $color_blue;
        }
    }
    .subtitle_site {
        font-size: 16px;
        color: #2c2c2c;
        line-height: 24px;
        margin-bottom: 24px;
        .link_about {
            color: #436aa5;
            font-weight: 700;
        }
    }
    .sub_menu {
        text-align: left;
        position: relative;
        li {
            display: inline-block;
            padding: 0 6px;
            @include mq_max (768px) {
                display: block;
                padding: 0px;
            }
        }
        a {
            font-weight: 700;
            color: #2b3990;
            transition: color .25s;
            &:hover {
                text-decoration: none;
                color: #436AA5;
            }
        }
    }
    .dropdown_toggle {
        .caret {
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: 2px;
            vertical-align: middle;
            border-top: 4px dashed;
            border-top: 4px solid\9;
            border-right: 4px solid transparent;
            border-left: 4px solid transparent;
        }
        &:focus {
            text-decoration: none;
        }
    }
    .dropdown_menu {
        position: absolute;
        top: 20px;
        left: 0px;
        width: 100%;
        display: none;
        z-index: 1;
        background-color: #fff;
        box-shadow: 0px 1px 1px 0px #ccc;
        border-radius: 0 0 5px 5px;
        padding: 5px 15px;
        li {
            width: 50%;
            float: left;
            padding: 0px;
        }
        a {
            white-space: nowrap;
            color: #436aa5
        }
        @include mq_max (1200px) {
            top: 45px;
        }
        @include mq_max (992px) {
            top: 20px;
        }
        @include mq_max (768px) {
            position: relative;
            box-shadow: none;
            top: 0px;
            li {
                float: none;
                width: 100px;
            }
        }
    }
    .social {
        ul {
            text-align: left;
            li {
                display: inline-block;
                margin-right: 3px;
            }
            li:nth-child(1) a {
                background-position: 0 -96px;
            }
            li:nth-child(2) a {
                background-position: 0 -64px;
            }
            li:nth-child(3) a {
                background-position: 0 -32px;
            }
            li:nth-child(4) a {
                background-position: 0 0;
            }
            a {
                height: 32px;
                width: 32px;
                display: inline-block;
                background-image: url(../img/sprite.png);
                /*border-radius: 50%;
                background-color: $bg_solial_head;
                color: #fff;
                font-size: 20px;
                line-height: 31px;
                text-align: center;*/
            }
        }
    }
    @include mq_max (1200px) {
        padding-top: 80px;
        padding-bottom: 90px;
        .title_site {
            margin-top: 45px;
        }
    }
    @include mq_max (992px) {
        padding-bottom: 70px;
        .title_site {
            margin-top: 0px;
        }
        .wrap_chess_img {
            text-align: center;
            margin-top: 30px;
            img {
                display: inline-block;
            }
        }
    }
}


/* end main */


/* lets_play */

.lets_play {
    position: relative;
    padding: 60px 0;
    background: url(../img/lets_play.jpg) left center no-repeat;
    background-size: cover;
    overflow: hidden;
    .container {
        position: relative;
        z-index: 1;
    }
    .col_text {

    }
    .title_block {
        margin-bottom: 17px;
    }
    .btn_play {
        width: 190px;
        height: 45px;
        margin-top: 20px;
        display: inline-block;
        -webkit-border-radius: 22px;
        -moz-border-radius: 22px;
        border-radius: 22px;
        background-color: #fdc00e;
        font-size: 24px;
        font-weight: 600;
        color: #2b3990;
        line-height: 45px;
        text-align: center;
        text-decoration: none;
        &:hover {
            background-color: #e9bd25;
        }
    }
    .col_text {
        margin-bottom: 20px;
    }
    @include mq_max (768px) {
        height: auto;
        padding: 70px 0;
    }
    .figure1 {
        position: absolute;
        top: 47px;
        left: 172px;
        width: 141px;
        height: 233px;
        background-image: url(../img/pawn.png);
    }
    .figure2 {
        position: absolute;
        top: -227px;
        left: 496px;
        width: 159px;
        height: 314px;
        background-image: url(../img/bishop.png);
        z-index: 0;
    }
    .figure3 {
        position: absolute;
        top: 66px;
        left: 872px;
        width: 155px;
        height: 335px;
        background-image: url(../img/king.png);
    }
    .figure4 {
        position: absolute;
        top: -48px;
        left: 1300px;
        width: 141px;
        height: 233px;
        background-image: url(../img/pawn.png);
    }
    .figure5 {
        position: absolute;
        top: 167px;
        left: 1650px;
        width: 123px;
        height: 221px;
        background-image: url(../img/rook.png);
    }
    .figure6 {
        position: absolute;
        top: 250px;
        left: 496px;
        width: 159px;
        height: 314px;
        background-image: url(../img/bishop.png);
        z-index: 0;
    }
    .figure7 {
        position: absolute;
        top: 300px;
        left: 1300px;
        width: 141px;
        height: 233px;
        background-image: url(../img/pawn.png);
    }
}


/* end lets_play */


/* gallery */

.gallery {
    padding: 58px 0 103px 0;
    background: #ebebeb url(../img/bg_gallery.jpg) center no-repeat;
    background-size: contain;
    .title_block {
        margin-bottom: 40px;
    }
    .wrap_gallery {
        padding: 0 30px;
        .thumb_gallery {
            width: 200px;
        }
        .gallery-poster {
            background-color: rgba(0, 0, 0, .1);
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            -webkit-transition: background-color .15s ease 0s;
            -o-transition: background-color .15s ease 0s;
            transition: background-color .15s ease 0s;
            img {
                left: 50%;
                margin-left: -10px;
                margin-top: -10px;
                opacity: 0;
                position: absolute;
                top: 50%;
                -webkit-transition: opacity .3s ease 0s;
                -o-transition: opacity .3s ease 0s;
                transition: opacity .3s ease 0s;
            }
        }
        a {
            overflow: hidden;
            position: relative;
            display: inline-block;
            float: left;
            &:hover>img {
                -webkit-transform: scale3d(1.1, 1.1, 1.1);
                transform: scale3d(1.1, 1.1, 1.1);
            }
            &:hover .gallery-poster {
                background-color: rgba(43, 57, 144, .82);
                img {
                    opacity: 1;
                    -webkit-transition: opacity .3s ease 0s;
                    -o-transition: opacity .3s ease 0s;
                    transition: opacity .3s ease 0s;
                }
            }
        }
        @include mq_max (768px) {
            padding: 0px;
        }
    }
    @include mq_max (768px) {
        padding: 50px 0 70px;
    }
}


/* gallery */


/*become_involved*/

.become_involved {
    height: 434px;
    padding-top: 49px;
    background: url(../img/bg_become_involved.jpg) center no-repeat;
    background-size: cover;
    .title_block {
        margin-bottom: 19px;
    }
    .text_block {
        margin-bottom: 35px;
    }
    .social {
        ul {
            text-align: left;
            li {
                display: inline-block;
                margin-right: 3px;
            }
            li:nth-child(1) a {
                background-position: 0 -96px;
            }
            li:nth-child(2) a {
                background-position: 0 -64px;
            }
            li:nth-child(3) a {
                background-position: 0 -32px;
            }
            li:nth-child(4) a {
                background-position: 0 0;
            }
            a {
                height: 32px;
                width: 32px;
                display: inline-block;
                background-image: url(../img/sprite_2.png);
                /*border-radius: 50%;
                background-color: $bg_solial_head;
                color: #fff;
                font-size: 20px;
                line-height: 31px;
                text-align: center;*/
            }
        }
    }
    @include mq_max (768px) {
        height: auto;
        padding: 70px 0;
    }
    .btn_contact {
        width: 190px;
        height: 45px;
        display: inline-block;
        -webkit-border-radius: 22px;
        -moz-border-radius: 22px;
        border-radius: 22px;
        background-color: #fdc00e;
        font-size: 24px;
        font-weight: 600;
        color: #2b3990;
        line-height: 45px;
        text-align: center;
        text-decoration: none;
        &:hover {
            background-color: #e9bd25;
        }
    }
}


/*end become_involved*/

/* footer_home */
.footer_home {
    height: 86px;
    background-color: #ebebeb;
    .copy {
        font-weight: 600;
        color: $color_blue;
        line-height: 86px;
    }
}
/* end footer_home */