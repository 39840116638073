/* heritage */

.text_page {
  padding: 125px 0 95px;
  .title_page {
    font-size: 28px;
    font-weight: 700;
    color: $color_blue;
    margin-bottom: 60px;
  }
  p {
    line-height: 24px;
    margin-bottom: 10px;
  }  
  .separate {
    transform: translate(2px, 0);  
    margin: 0;
    margin-top: 58px;
    margin-bottom: 78px;
    &:last-child {
      margin-bottom: 23px;
      font-weight: 500;
      letter-spacing: 0.5px;
    }
  }
  .separate-line {
    transform: translate(-8px, 0);
    margin: 0 auto;
    margin-top: 29px;
    text-align: center;
    height: 2px;
    width: 517px;
    background-color: #2B3991;
  }
}

.heritage {
  figure {
    width: 50%;
    float: left;
    margin-right: 20px;
    figcaption {
      text-align: center;
      margin-bottom: 10px;
    }
    @include mq_max (768px) {
      width: 100%;
      margin-right: 0px;
    }
  }
}


/* end heritage */
