/* blog */

.blog {
	padding-top: 105px;
	padding-bottom: 95px;
	.single_post {
		padding-top: 160px;
	}
	.title_blog {
		font-size: 26px;
		font-weight: 600;
		color: $color_blue;
		margin-bottom: 60px;
	}
	.item_post {
		clear: both;
		overflow: hidden;
		margin-bottom: 60px;
		figure {
			position: relative;
			display: inline-block;
			img {
				max-width: 100%;
			}
			@include mq_max (768px) {
				margin-bottom: 20px;
			}
		}
		.label_new {
			position: absolute;
			top: 0px;
			right: 20px;
			width: 45px;
			height: 50px;
			display: inline-block;
			background: url('../img/label_new.png');
			font-size: 16px;
			font-weight: 600;
			color: $color_blue;
			text-align: center;
			line-height: 45px;
		}
		.title_post a {
			display: inline-block;
			font-size: 24px;
			font-weight: 600;
			color: $color_blue;
			margin-bottom: 15px;
		}
		.date {
			display: inline-block;
			font-size: 16px;
			color: #8f8f8f;
			margin-right: 20px;
			margin-bottom: 15px;
		}
		.author {
			display: inline-block;
			font-size: 16px;
			color: #fdc00e;
			margin-bottom: 15px;
		}
		.excerpt {
			font-size: 16px;
			color: #2c2c2c;
			line-height: 24px;
			margin-bottom: 15px;
		}
		.read_more {
			font-size: 16px;
			font-weight: 600;
			color: $color_blue;
		}
		a:hover {
		    text-decoration: none;
    		color: #436aa5;
		    transition: color .25s;
		}
	}
	@include mq_max (992px) {
		padding-top: 100px;
		padding-bottom: 0px;
	}
	&.single_post {
		.label_new {
			top: 0px;
			right: 80px;
			width: 99px;
			height: 109px;
			display: inline-block;
			background: url('../img/label_new_single.png');
			font-size: 30px;
			line-height: 100px;
			@include mq_max (768px) {
				right: 20px;
				width: 45px;
				height: 50px;
				display: inline-block;
				background: url('../img/label_new.png');
				font-size: 16px;
				line-height: 45px;
			}
		}
		figure {
			margin-bottom: 25px;
		}
		.title_post {
			font-weight: 600;
			color: $color_blue;
			font-size: 36px;
			margin-bottom: 40px;
			@include mq_max (768px) {
				font-size: 24px;
				margin-bottom: 15px;
			}
		}
		.date,
		.author {
			margin-bottom: 30px;
			@include mq_max (768px) {
				margin-bottom: 15px;
			}
		}
		.social_share {
			float: right;
			margin-top: -15px;
			ul {
				text-align: right;
				li {
					display: inline-block;
					margin-left: 4px;
					a {
						width: 32px;
						height: 32px;
						display: inline-block;
						background: url('../img/sprite.png');
					}
					&:nth-child(1) a {
						background-position: 0 -96px;
					}
					&:nth-child(2) a {
						background-position: 0 -64px;
					}
					&:nth-child(3) a {
						background-position: 0 -32px;
					}
				}
			}
			@include mq_max (768px) {
				float: none;
				margin-top: 0px;
				margin-bottom: 15px;
				display: block;
				ul {
					text-align: left;
				}
			}
		}
	}
}